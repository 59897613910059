import { createApp } from 'vue'
import store from './utils/store/index'
// import { createStore } from 'vuex'
import ElementPlus from 'element-plus'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import * as echarts from 'echarts'
import Bus from './utils/eventBus'/// mitt 总线程引入

const app = createApp(App)
app.config.globalProperties.$bus = Bus
app.config.globalProperties.echarts = echarts
app.config.globalProperties.$message = ElementPlus.ElMessage
app.use(ElementPlus, { locale })
app.use(VXETable)
app.use(store)
app.use(router)
app.mount('#app')
