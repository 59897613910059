<template>
  <!-- 头部导航条 -->
  <navigation v-if="$route.meta.navigation"></navigation>
  <router-view/>
</template>
<script>
import navigation from './components/navigation/index.vue'
import './assets/davinci.css'
export default {
  components: { navigation }
}
</script>
<style lang="less">
*{
  margin: 0;
  padding: 0;
  list-style: none;
}
body{
  background: #F5F7FA;
  font-size: 14px;
}
input{
  outline:none;
  border: 1px solid #BFBFBF;
  border-radius: 6px;
}
// input:focus{
//     border:1px solid #262626 !important;
// }

:root {
  // .is-disabled {
  //   border: 1px solid green !important;
  // }
  // 弹框标题样式
  .el-dialog__header, .el-dialog__body, .el-dialog__footer{
    padding: 0;
  }
  // 开关样式
  .el-switch {
    --el-switch-button-size: 14px;
    --el-switch-height: 18px;
  }
  .el-switch.is-checked .el-switch__core {
    width: 34px !important;
  }
  .el-switch__core{
    width: 34px !important;
  }
  .el-checkbox{
    font-weight: 400;
  }
  // 穿梭框
  .el-transfer-panel .el-transfer-panel__header{
    background: #F2F2F2;
    height: 100%;
  }
  .el-transfer__buttons{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction:column;
  }
  .el-button+.el-button{
    margin: 0;
  }
  .el-transfer-panel .el-transfer-panel__header .el-checkbox{
    display: flex;
    align-items: center;
    height: 53px;
  }
  // 穿梭框列头
  .el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label{
    font-size: 14px;
  }
  .el-transfer {
    --el-transfer-border-color: var(--el-border-color-lighter);
    --el-transfer-border-radius: var(--el-border-radius-base);
    --el-transfer-panel-width: 256px;
    --el-transfer-panel-header-height: 40px;
    --el-transfer-panel-header-bg-color: var(--el-bg-color-base);
    --el-transfer-panel-footer-height: 40px;
    --el-transfer-panel-body-height: 300px;
    --el-transfer-item-height: 30px;
    --el-transfer-filter-height: 32px;
}
  // 表格边框
  .el-table{
    border: none !important;
  }
  .el-input-group__append{
    background: #FFFFFF;
    padding: 0;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    // overflow: hidden;
    // border: 1px solid #262626;
    border: none;
  }
  .el-checkbox{
    height: 33px;
  }
  // // 表格间距
  // .el-table .el-table__cell{
  //   padding: 20px 0;
  // }
  // 页码选中样式
  .el-pager li.active{
     background: #262626;
     color: #FFFFFF;
     border-radius: 8px;
     font-size: 12px;
   }
   .el-pager li {
     margin: 0 6px;
     text-align: center;
   }
  //  设置选中宽度
   .el-pagination{
     --el-pagination-button-width: 30px;
     --el-pagination-button-height: 30px;
   }
  .el-dialog--center{
    border-radius: 12px;
  }
  // 时间日期选择器样式
  .el-picker__popper.el-popper[role=tooltip]{
    border-radius: 10px;
    padding: 6px;
  }
  .el-date-picker__time-header{
    border: none;
  }
  // 日期选择器确认按钮样式
  .el-button--mini{
    border-radius: 10px;
  }
  // 时间选择器位置
  // .el-time-panel{
  //   left:180px;
  //   top: -25px;
  //   border-radius: 10px;
  // }
  // 时间选择器确认取消按钮
  .el-time-panel__footer{
    display: none;
  }
  // 输入框
  .el-input__inner{
    height: 36px;
  }
  .el-input-number__increase{
    display: none;
  }
  .el-input-number.is-without-controls .el-input__inner{
    padding: 0 !important;
  }
  .el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
    display: none;
  }
  // 日期选择框
  .el-date-editor.el-input, .el-date-editor .el-input__inner{
    width: 243px;
    height: 36px;
    border-radius: 6px;
  }
  .el-dialog__title{
    font-size: 14px;
  }
  .el-table .cell{
    overflow: inherit !important;
  }
  .el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
    display: none;
  }
  .el-collapse-item__arrow{
    display: none;
  }
  .el-collapse-item__header,.el-collapse{
    border: none;
  }
  .el-collapse-item__content{
    padding-bottom:0
  }
  // 超链接移入样式
  .el-link--inner:hover{
    color: #606266;
  }
  // 功能按钮
  // 实底按钮样式
  .el-button--primary, .el-button--default {
    // background: #262626;
    border: 1px solid #262626;
    color: #262626;
    background: #ffffff;
    border-radius: 6px;
  }
.el-button--primary:focus, .el-button--default:focus{
    background: #262626 !important;
  }
  // 实底按钮hover样式
  .el-button--primary:hover, .el-button--default:hover{
    color: #262626 !important;
    background: #F7F7F7 !important;
    border: 1px solid #262626 !important;
  }
  // 实底按钮active样式
  .el-button--primary:active, .el-button--default:active{
    // background: #262626;
    color: #ffffff !important;
    background: #262626 !important;
    border: 1px solid #262626 !important;
  }
  // 实底按钮不可点击的事件
  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover{
    background: #FFFFFF !important;
    color: #DADBDE !important;
    border: 1px solid #DADBDE !important;
  }
  // 镂空按钮hover样式
  .el-button--default:hover{
    background: #606266;
    color: #FFFFFF;
    border: none;
  }
    --el-color-white: #ffffff !important;
    --el-color-black: #000000 !important;
    --el-color-primary: #262626 !important;
    --el-color-primary-light-1: #53a8ff !important;
    --el-color-primary-light-2: #262626 !important;
    --el-color-primary-light-3: #79bbff !important;
    --el-color-primary-light-4: #8cc5ff !important;
    --el-color-primary-light-5: #a0cfff !important;
    --el-color-primary-light-6: #b3d8ff !important;
    --el-color-primary-light-7: #c6e2ff !important;
    --el-color-primary-light-8: #d9ecff !important;
    --el-color-primary-light-9: #efefef !important;
    --el-color-success: #67c23a;
    --el-color-success-light: #e1f3d8;
    --el-color-success-lighter: #f0f9eb;
    --el-color-warning: #e6a23c;
    --el-color-warning-light: #faecd8;
    --el-color-warning-lighter: #fdf6ec;
    --el-color-danger: #f56c6c;
    --el-color-danger-light: #fde2e2;
    --el-color-danger-lighter: #fef0f0;
    --el-color-error: #f56c6c;
    --el-color-error-light: #fde2e2;
    --el-color-error-lighter: #fef0f0;
    --el-color-info: #909399;
    --el-color-info-light: #e9e9eb;
    --el-color-info-lighter: #f4f4f5;
    --el-text-color-primary: #303133;
    --el-text-color-regular: #606266;
    --el-text-color-secondary: #909399;
    --el-text-color-placeholder: #c0c4cc;
    --el-border-color-base: #dcdfe6;
    --el-border-color-light: #e4e7ed;
    --el-border-color-lighter: #ebeef5;
    --el-border-color-extra-light: #f2f6fc;
    --el-background-color-base: #f5f7fa;
    --el-border-width-base: 1px;
    --el-border-style-base: solid;
    --el-border-color-hover: var(--el-text-color-placeholder);
    --el-border-base: var(--el-border-width-base) var(--el-border-style-base) var(--el-border-color-base);
    --el-border-radius-base: 6px;
    --el-border-radius-small: 2px;
    --el-border-radius-round: 20px;
    --el-border-radius-circle: 100%;
    --el-box-shadow-base: 0 2px 4px rgba(0, 0, 0, 0.12),0 0 6px rgba(0, 0, 0, 0.04);
    --el-box-shadow-light: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    --el-svg-monochrome-grey: #dcdde0;
    --el-fill-base: var(--el-color-white);
    --el-font-size-extra-large: 20px;
    --el-font-size-large: 18px;
    --el-bg-color-base: #F8F9FA; // 表格移入颜色
    --el-switch-button-size: 10px;
    --el-disabled-border-color: #FFFFFF;
}
</style>
