<template>
  <el-header class="header fl-c-sb">
    <div class="fl-c-fs">
      <img src="@/assets/image/logo.png" alt="" class="logo cursor">
      <ul class="fl-c-fs navigation">
        <li :class=" index == checkIndex ? 'checked cursor':'cursor'" v-for="(item, index) in menu" :key="index" @click="handleClick(item,index)">{{item.name}}</li>
      </ul>
    </div>
    <div class="fl-c-fs user">
      <el-dropdown>
        <div>
          用户名
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="quit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
  <div class="line"></div>
      <span>管理员</span>
    </div>
  </el-header>
</template>

<script>
export default {
  name: 'home',
  components: { },
  data () {
    return {
      menu: [
        { name: '车辆数据', page: 'home' },
        { name: '车辆地图', page: 'vehicles' },
        { name: '固件信息', page: 'firmware' }
      ],
      checkIndex: 0
    }
  },
  mounted () {
    console.log(this.$route.path)
    if (this.$route.path === '/home') {
      this.checkIndex = 0
    } else if (this.$route.path === '/vehicles') {
      this.checkIndex = 1
    } else if (this.$route.path === '/firmware') {
      this.checkIndex = 2
    }
  },
  methods: {
    handleClick (item, index) {
      this.checkIndex = index
      console.log(item)
      this.$store.dispatch('refresh', { time: new Date(), refreshType: false, refreshTime: 60, refreshDisabled: false })
      this.$router.push({ name: item.page })
    },
    quit () {
      window.localStorage.removeItem('token')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="less" scoped>
  .header{
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    z-index: 10;
    color: #262626;
    border-bottom: 1px solid rgba(191, 191, 191, 0.5);
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 16px;
    padding: 0 40px;
    .logo{
      width: 153px;
      height: 24px;
      margin-right: 200px;
    }
    li{
      margin-right: 100px;
      color: #8C8C8C;
      width: 70px;
    }
    li:hover{
      color:#262626;
      font-weight: 600;
    }
    .checked{
      color:#262626;
      font-weight: 600;
    }
    .user div{
      margin-right: 5px;
      // background: #000000;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      // text-align: LEFT;
      color: #000000;
      line-height: 16px;
    }
    .user span{
      -webkit-transform-origin-x: 0;
      -webkit-transform: scale(0.70);
      padding: 2px 6px;
      background: #0AB28ACC;
      border-radius: 4px;
      color: #FFFFFF;
    }
  }
</style>
