import { createStore } from 'vuex'

export default createStore({
  state: {
    paging: { pageSize: 20, pageNum: 1, totalPage: 1, totalCount: 5 }, // 分页配置 条/页 当前页 目标页 总条数
    refresh: { time: new Date(), refreshType: false, refreshTime: 60, refreshDisabled: false }, // 自动刷新配置 时间 是否开启自动刷新 自动刷新间隔 是否被禁用
    requestHeader: [], // 请求用的表头数组
    requestParam: [], // 请求用的参数数组
    requestParamType: false, // 请求用是否交并集
    allHeader: [], // 所有表头
    tableHeader: [], // 所有展示表头
    requestVin: '', // 请求用的vin码 详情页用
    filtrateType: false, // 筛选状态
    listType: false, // 编辑列状态
    firmwareData: [], // 固件表格数据
    historyFirmwareData: [] // 历史固件列表
  },
  mutations: {
    // 分页配置
    paging (state, data) { state.paging = data },
    // 自动刷新间隔
    refresh (state, data) { state.refresh = data },
    // 请求用的参数数组
    requestParam (state, data) { state.requestParam = data },
    // 请求用是否交并集
    requestParamType (state, data) { state.requestParamType = data },
    // 请求用的表头数组
    requestHeader (state, data) { state.requestHeader = data },
    // 所有表头
    allHeader (state, data) { state.allHeader = data },
    // 展示表头
    tableHeader (state, data) { state.tableHeader = data },
    // 请求用的vin码 详情页用
    requestVin (state, data) { state.requestVin = data },
    // 筛选状态
    filtrateType (state, data) { state.filtrateType = data },
    // 编辑列状态
    listType (state, data) { state.listType = data },
    // 固件表头数据
    firmwareData (state, data) { state.firmwareData = data },
    // 历史固件表头数据
    historyFirmwareData (state, data) { state.historyFirmwareData = data }
  },
  actions: {
    // 分页配置
    paging (context, data) { context.commit('paging', data) },
    // 自动刷新间隔
    refresh (context, data) { context.commit('refresh', data) },
    // 请求用的参数数组
    requestParam (context, data) { context.commit('requestParam', data) },
    // 请求用是否交并集
    requestParamType (context, data) { context.commit('requestParamType', data) },
    // 自动刷新间隔
    requestHeader (context, data) { context.commit('requestHeader', data) },
    // 所有表头
    allHeader (context, data) { context.commit('allHeader', data) },
    // 展示表头
    tableHeader (context, data) { context.commit('tableHeader', data) },
    // 请求用的vin码 详情页用
    requestVin (context, data) { context.commit('requestVin', data) },
    // 筛选状态
    filtrateType (context, data) { context.commit('filtrateType', data) },
    // 编辑列状态
    listType (context, data) { context.commit('listType', data) },
    // 固件表头数据
    firmwareData (context, data) { context.commit('firmwareData', data) },
    // 历史固件表头数据
    historyFirmwareData (context, data) { context.commit('historyFirmwareData', data) }
  },
  modules: {}
})
