const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      navigation: true
      // checkLogin: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      navigation: true
      // checkLogin: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
  },
  {
    path: '/chart',
    name: 'chart',
    meta: {
      navigation: true
      // checkLogin: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/chart.vue')
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    meta: {
      navigation: true
      // checkLogin: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/vehicles.vue')
  },
  {
    path: '/firmware',
    name: 'firmware',
    meta: {
      navigation: true
      // checkLogin: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/firmware.vue')
  },
  {
    path: '/firmwareDetail',
    name: 'firmwareDetail',
    meta: {
      navigation: true
      // checkLogin: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/firmwareDetail.vue')
  }
]
export default routes
