import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  if (window.localStorage.getItem('token')) {
    // 有token
    if (to.path === '/') {
      next({
        path: 'home'
      })
    } else {
      next()
    }
  } else {
    // 没有token
    if (to.path === '/') {
      next()
    } else {
      next({
        path: '/'
      })
    }
  }
})

export default router
